import React, {useState} from 'react';
import './Details.scss';
function Details(props) {
    function Btn(props) {
        const btn = props.btn;

        // console.log(primary_color)
        if (btn) {
            return (<div className='details_btn background-color'>
                {btn}
            </div>)
        }
    }
    const html = props.details;
    const btn = props.btn;


    const [display, setDisplay] = useState(false);

    return (
        <div style={{backgroundColor: '#fff',marginTop:"-1px",marginBottom:"-1px"}}>
            <div  style={{display:display?'none':'block',textAlign:"center"}} onClick={()=>setDisplay(!display)}>
                <Btn btn={btn}> </Btn>
            </div>
            <div style={{display:display?'block':'none',overflow:'hidden'}} className='details_html' dangerouslySetInnerHTML={{__html: (html)}}>

            </div>
            <div  style={{  color: "#5a5a5a",display:display?'block':'none',fontSize:"20px",textAlign:"right",'marginRight':'40px'}} onClick={()=>setDisplay(!display)}>
               收起 &lt;
            </div>

        </div>

    );
}

export default Details;
