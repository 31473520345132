import React from "react";
import './nav.scss';
import Config from "../../Config";

class Nav extends React.Component {
    handleClick (url,e) {
        if(this.props.go){
            this.props.go(url)
        }
        e.preventDefault();
    };

    render() {
        let CDN=Config.CDN;


        return (<div className='home-nav'>
            <div>
                <a href={this.props.links['love-donate']}>
                <img  src={CDN+'./assets/home/assets/menu_1.png'}/>
                </a>
            </div>
            <div onClick={this.handleClick.bind(this, '#sjgy')}>
                <img src={CDN+'./assets/home/assets/menu_2.png'}/>
            </div>
            <div onClick={this.handleClick.bind(this, '#wzacx')}>
                <img src={CDN+'./assets/home/assets/menu_3.png?v=1'}/>
            </div>
            <div onClick={this.handleClick.bind(this, '#dthb')}>
                <img src={CDN+'./assets/home/assets/menu_4.png'}/>
            </div>
            <div>
                <a href={this.props.links['charity-foundation']}>
                <img src={CDN+'./assets/home/assets/menu_5.png'}/>
                </a>
            </div>
        </div>);
    }
}

export default Nav;