import axios from "axios";
import React from "react";
import './PopUpAlert.scss';
import MediaReports from "../blocks/MediaReports";
import Config from "../../../Config";
import Stories from "../blocks/Stories";
import Details from "../blocks/Details";
import EventBlocks from "../blocks/EventBlocks";
import AnimateSvg from "../blocks/AnimateSvg";

class PopUpAlert extends React.Component {
    constructor(props) {
        super(props);
        let alert = props.alert
        this.state = {
            error: null,
            show: Boolean(alert)
        };
    }

    componentDidMount() {

    }


    close() {
        this.setState({
            show: false

        })


    }

    render() {
        const {show} = this.state;
        let CDN=Config.CDN;
        if (!show) {
            return;
        }
 else {
            return (<div className={'pop-up-alert-container '} onClick={this.close.bind(this)}>
                <div  className='pop-up-content-all'>

                    <div >
                        <img style={{boxShadow:'6px 8px 13px 1px rgba(0,0,0,0.2)',marginTop:'40px'}} src={CDN+'./assets/home/alert.jpg'}/>
                    </div>
                </div>

            </div>)

        }


    }
}

export default PopUpAlert;