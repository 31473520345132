import {React, useRef, useLayoutEffect} from "react";
import AnimateSvg from "./AnimateSvg";
import {gsap} from "gsap";
import Config from "../../../Config";

function CharityGallery(props) {


    function click(e) {
        props.popUp('charity-gallery');
        // console.log(this.props)
    }

    const app = useRef();


    useLayoutEffect(() => {

        setTimeout(function (){
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".charity_gallery_image_dom",
                    start: "-200 center",
                    end: "bottom bottom",
                    scrub: 1,
                    // markers: true
                }
            });

            const ease = "sine.inOut"


            tl.to(".bottom_car", {x: -200,y: 100,opacity:1,scale:1, duration:70,ease: ease},"<+=1")
        },200)




        return function (){


        }

    }, []);
    let CDN=Config.CDN;

    return (<div className='image-title' style={{'marginTop': '40px'}}>
        <div style={{marginTop: 80}}>
            <img src={CDN+'./assets/home/assets/charity_gallery.png?v=1'}/>
        </div>
        <div style={{marginTop: 50}} onClick={click} >
            <img src={CDN+'./assets/home/assets/charity_gallery_btn.png?v=1'}/>
        </div>
        <div className="charity_gallery_image_dom" style={{marginTop: 20,position:"relative",height:'820px'}}>
            {/*<img style={{display:"block"}} src='./assets/home/assets/charity_gallery_image.png'/>*/}

            <AnimateSvg className='background' width='750px' height='820px'
                        src={CDN+'./assets/home/assets/bottom_v3.svg'} backup={CDN+'./assets/home/assets/bottom_v3.png'}></AnimateSvg>

            <div className='bottom_car' style={{position:"absolute",bottom:"100px",left:'270px'}}>
                <img src={CDN+'./assets/home/assets/buttom_car.png'}/>
                {/*<AnimateSvg  width='332px' height='191px' src='./assets/home/assets/car.svg'></AnimateSvg>*/}
            </div>

        </div>

    </div>)

}

export default CharityGallery;