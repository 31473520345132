import React from "react";

class ImageTitle extends React.Component {
    render() {
        return (<div className='image-title' style={{'marginTop': '40px'}}>
            <img src={this.props.src}/>
        </div>)
    }
}

export default ImageTitle;