import React, {useState} from "react";
import './MediaReports.scss'
function DateDiv(props) {
    if(!props.date){
        return null;
    }
    const date=props.date.split(' ')[0];
    return (
        <div className="square" style={{display: 'inline',marginLeft:'20px'}}>
            {date}
        </div>
    );
}
function MediaReports(props){
    const limit_count=3;
    const [over, setOver] = useState(props.list&&props.list.length>limit_count);
    const [display, setDisplay] = useState(false);


        if(!props.list){
            return null;
        }







        return (<div className='MediaReports' >
            <div className='container border-color'>
                <div className='MediaReports-title'>媒体报道</div>
                {props.list.map((item,index) => (
                    <div className='report' key={index} style={{display:(index<limit_count||display)?'block':'none',}}>
                        <div className='report-title before_background-color'>
                            <a href={item.url}>
                            {item.title}</a>
                        </div>
                        <div className='report-info'>
                            {item.media}
                            <DateDiv date={item.date}></DateDiv>
                        </div>
                    </div>
                ))}
                <div className='more_btn' style={{display:over&&!display?'block':'none',textAlign: "right"}} onClick={()=>setDisplay(!display)}>
                    更多报道>
                </div>
            </div>


        </div>)

}

export default MediaReports;