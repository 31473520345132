import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import './Stories.scss';
import Config from "../../../Config";
import React, {useState} from 'react';

export default (props) => {
    const stories = props.stories;

    const title=props.title;

    const site = Config.API;
    // console.log(title)

    if (!stories || stories.length ===0) {
        return null;
    }

    const [html, setHtml] = useState(stories[0].text);

    function StoryImage(props) {
        const item = props.item;
        console.log(item)
        if (item.image_url) {
            return  <img src={site + item.image_url}/>;
        }
        return  null;
    }


    return (
        <div style={{background:'#fff'}}>
            <div className='story_title color'>
                {title}


            </div>


            <Swiper
                modules={[Pagination,Autoplay]}
                spaceBetween={0}
                autoplay={{
                    delay: 5000,
                }}

                autoHeight={true}
                slidesPerView={1} pagination={true}
                onSlideChange={(swiper) => setHtml(stories[swiper.activeIndex].text)}
                onSwiper={(swiper) => console.log(swiper)

                }
            >
                {stories.map((item, index) => (
                    <SwiperSlide className='story_image' key={index}>
                        <StoryImage item={item} />
                        <div className='story_html' style={{display:item.text?"block":'none'}} dangerouslySetInnerHTML={{__html: item.text}}>

                        </div>

                    </SwiperSlide>
                ))}

            </Swiper>

        </div>
    );
};