import React from "react";
import 'animate.css';
import './P1.scss';
import Nav from '../../../components/nav/Nav'
// import BACKGROUND from '../../../../public/assets/home/assets/p1_bg.jpg'
// import DD_LOGO from '../../../../public/assets/home/dd_charity.png'
// import TITLE_PNG from '../../../../public/assets/home/assets/title.png'
// import MAN_DOG from '../../../../public/assets/home/assets/p1_man_dog.png'
// import CAR from '../../../../public/assets/home/assets/p1_car.png'

import {gsap} from "gsap";
import AnimateSvg from "../blocks/AnimateSvg";
import Config from "../../../Config";

class P1 extends React.Component {

    componentDidMount() {


        this.tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".page-1",
                start: "40 top",
                end: "400 top",
                scrub: 1,
                // markers: true
            }
        });

        const ease = "sine.inOut"

        this.tl.to(".logo", {y: -50, duration: 10, ease: ease})
        this.tl.to(".title", {y: -50,opacity:0, duration: 20,ease: ease},"<+=1")

        this.tl.to(".car", {x: 30,y: 10,opacity:1, duration:70,ease: ease},"<+=1")
        this.tl.to(".man_dog", {x: 6,opacity:1, duration: 50,ease: ease},"<+=10")


        // this.tl.to(".Home-p1-description", {y: -50, duration: 3, ease: ease}, 2)



    }

    componentWillUnmount() {
        this.tl.kill()



    }
    go(uri){
        this.props.scrollTo(
            uri
        )
    }
    render() {
        let CDN=Config.CDN;

        return (<div className=' page-1' >
            {/*<img className='background' src='./assets/home/assets/p1_bg.jpg'/>*/}
            <AnimateSvg className='background' width='750px' height='1060px' src={CDN+'./assets/home/assets/home.svg'} backup={CDN+'./assets/home/assets/home.png'}></AnimateSvg>
            <div className='info'>
                {/*<div className='cloud animate__animated animate__shakeX animate__slower animate__infinite' style={{right:-10,top:120}}>*/}
                {/*    <img src='./assets/home/assets/cloud_1.png'/>*/}
                {/*</div>*/}
                {/*<div className='cloud animate__animated animate__pulse animate__slower animate__infinite' style={{left:0,top:160}}>*/}
                {/*    <img src='./assets/home/assets/cloud_2.png'/>*/}
                {/*</div>*/}
                {/*<div className='cloud animate__animated animate__shakeX delay-1s animate__slower animate__infinite' style={{left:200,top:250}}>*/}
                {/*    <img src='./assets/home/assets/cloud_3.png'/>*/}
                {/*</div>*/}

            <div className='logo'>
                {/*<img src='./assets/home/dd_charity.png'/>*/}
            </div>
            <div className='title '>
                <img className='animate__animated animate__fadeInDown animate__fast ' src={CDN+'./assets/home/assets/title.png?v=1'}/>
            </div>
            <div className='man_dog'>
                <img src={CDN+'./assets/home/assets/p1_man_dog.png?v=2'}/>
            </div>
            <div className='car '>
                <img src={CDN+'./assets/home/assets/p1_car.png'}/>
                {/*<AnimateSvg  width='332px' height='191px' src='./assets/home/assets/car.svg'></AnimateSvg>*/}
            </div>
            <div className='nav'>
                <Nav go={this.go.bind(this)} links={this.props.links}></Nav>
            </div>
            </div>

        </div>);
    }
}

export default P1;