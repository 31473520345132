

let ua=navigator.userAgent.toLowerCase();

let svgmode=true;

if(ua.indexOf("passenger")>-1&&ua.indexOf("android")>-1){
    svgmode=false;
}
// svgmode=true;
const Config={
    API:process.env.NODE_ENV==="development"?"https://charity.datwork.cn/":'https://charity.datwork.cn/',
    CDN:process.env.NODE_ENV==="development"?"./":'./',
    //后面变量设置成滴滴平台内的     本地开发模式地址:正式地址
    // API:''
    SVG_MODE:svgmode
}
console.log(ua);
console.log(Config);
// console.log(process.env)

export default Config;