import Config from "../../../Config";

export default (props) => {
    const width = props.width || 'auto';
    const height = props.height || 'auto';
    const src = props.src
    const backup = props.backup
    const className=props.className

    let SVG_MODE=Config.SVG_MODE
    if(SVG_MODE){
        return (<object className={className} width={width} height={height} type="image/svg+xml" data={src}>

            <img width={width} height={height} src={src}/>

        </object>)
    }else{
        return (
            <div className={className} >
                <img width={width} height={height} src={backup}/>
            </div>

      )

    }


}


