// import logo from './logo.svg';
import './App.css';
import React from "react";
import {Routes, Route} from "react-router-dom";

import Home from "./components/home/Home";
import Article from "./components/article/Article";
// import Models from "./components/models/Models";
// import {useRef,useEffect} from "react";

// import {gsap} from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger"
// import ScrollSmoother from "gsap/ScrollSmoother"
//
//
// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

class App extends React.Component {


    componentDidMount() {


    }
    componentWillUnmount() {

    }

    render() {
        return (
            <div className="App" >
                <div className="App-content" >
                    <Routes>
                        <Route path="article" element={<Article />}>
                            <Route path=":slug" element={<Article />} />
                        </Route>
                        <Route index element={<Home/>}/>
                    </Routes>

                </div>


            </div>
        );
    }


}

export default App;
