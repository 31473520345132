
import './EventBlock.scss';
import Config from "../../../Config";
import React, {useState} from 'react';

export default (props) => {
    const nodes = props.events;

    const title=props.title;

    const site = Config.API;
    // console.log(title)

    if (!nodes || nodes.length ===0) {
        return null;
    }

    const [html, setHtml] = useState(nodes[0].text);

    function Node(props){
        const data=props.data;
        return (<div className='event_node'>
            <div className='event_year color'>{data.year}</div>
            <div className='event_line'></div>
            <div className='event_content event_node_content'>
                <div className='event_content_html' dangerouslySetInnerHTML={{__html: data.text}}>

                </div>

            </div>

        </div>)
    }


    return (
        <div style={{background:'#fff',paddingBottom:"40px",paddingTop:"20px"}}>
            <h2 className='color' style={{marginBottom:"50px"}}>
                {title}
            </h2>
                {nodes.map((item, index) => (
                    <Node data={item} key={index}>

                    </Node>
                ))}



        </div>
    );
};