
// import FooterImg from '../assets/footer.svg'
import './footer.scss'

import React from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollSmoother from "gsap/ScrollSmoother"
import Config from "../../../Config";


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        const firstBackground = "#ededed";
        let CDN=Config.CDN;
        return (
            <div className='footer-div' style={{background: firstBackground,overflow:'hidden',padding:50,fontSize:20,color:"#888"}} >
                <div>
                滴滴公益  |  建议与反馈
                </div>
                <div>
                统一社会信用代码：9111010859963405XW
                </div>
                <div>
                投诉与建议：didigongyi@didichuxing.com
                </div>
                <div style={{marginTop:'30px'}}>
                    <img src={CDN+'./assets/home/dd_charity_v1.png'}/>
                </div>

            </div>
        )
    }
}

export default Footer;