import axios from "axios";
import React from "react";
import './PopUp.scss';
import MediaReports from "../blocks/MediaReports";
import Config from "../../../Config";
import Stories from "../blocks/Stories";
import Details from "../blocks/Details";
import EventBlocks from "../blocks/EventBlocks";
import AnimateSvg from "../blocks/AnimateSvg";

class PopUp extends React.Component {
    constructor(props) {
        super(props);
        let page_slug = props.page_slug;
        console.log(props.close_icon)
            let close_icon=props.close_icon===false?false:true;
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
            page_slug: page_slug,
            close_icon:close_icon
        };
    }

    componentDidMount() {

    }

    loadData(page_slug) {
        if (!page_slug) {
            return
        }

        const that = this;
        axios.get(Config.API + '/dd/page/' + page_slug).then(function (response) {
            // handle success
            that.setState({
                isLoaded: true,
                data: response.data
            })

            console.log(response.data);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    close() {
        this.setState({
            error: null,
            isLoaded: false,
            data: []
        })
        this.props.close()

    }

    render() {
        const {error, isLoaded, data,close_icon} = this.state;
        let CDN=Config.CDN;
        if (!this.props.page_slug) {
            return;
        }

        if (false === isLoaded) {
            this.loadData(this.props.page_slug)
            return (<div className='pop-up-container'>
                <AnimateSvg src={CDN+'./assets/home/loading.svg'} width='100px' height='100px'></AnimateSvg>
            </div>)
        } else {
            return (<div className={'pop-up-container ' + data.primary_color}>
                <div  className='pop-up-content-all'>
                    <div className='pop-up-content'>


                        <div className='content' dangerouslySetInnerHTML={{__html: (data.content_html)}}>

                        </div>
                        <Details btn={data.details_btn_title} details={data.details}></Details>
                        <EventBlocks title={data.events_title} events={data.events}></EventBlocks>
                        <Stories title={data.stories_title} stories={data.stories}></Stories>
                        <MediaReports url={data.media_reports_url} list={data.media_reports}></MediaReports>
                        <div style={{display:data.featured_image_url?'block':'none',textAlign: 'left', backgroundColor: "#fff", paddingTop: "30px"}}>
                            <img src={data.featured_image_url} style={{maxWidth: '100%', display: "block"}}/>
                        </div>
                    </div>
                    <div className='logo' style={{padding: '30px', textAlign: "center"}}>
                        <img src={CDN+'./assets/home/dd_charity_v1.png'}/>
                    </div>


                </div>
                <div style={{display:close_icon==false?'none':''}} className='closeBtn' onClick={this.close.bind(this)}>
                    <img src={CDN+'./assets/home/assets/return.png'}/>
                </div>
            </div>)

        }


    }
}

export default PopUp;