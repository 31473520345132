import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";

import WechatShare from "johnny-wechat-share/src";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HashRouter>
            <App />
         </HashRouter>
     </React.StrictMode>

);
// https://charity.datwork.cn/api/wechatjsapi/datwork/js

var option = {
    api: 'https://charity.datwork.cn/api/wechatjsapi/datwork/js', //授权链接
    debug: false,
    jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage'],
    shareData: {
        appmessage: {
            title: "滴滴公益 一路同行",//好友分享标题
            desc: "",//好友分享描述
            img_url: "https://charity.datwork.cn/storage/app/media/wechat_share_400x_400.jpg",//好友分享图片
            link: "https://charity.datwork.cn/build/"//朋友圈分享链接
        }, timeline: {
            title: "滴滴公益 一路同行",//好友分享标题
            img_url: "https://charity.datwork.cn/storage/app/media/wechat_share_400x_400.jpg",//朋友圈分享图片
            link: "https://charity.datwork.cn/build/"//朋友圈分享链接
        }
    }
};

// var share = new WechatShare('http://h5.2smart.cn',option);
const share = new WechatShare(option);
// share.set('appmessage', 'title', "使用 set 函数重新设置标题");
// share.set('appmessage', 'link', "http://h5.2smart.cn");
// share.update()//更新分享内容


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
