import './Home.scss';
import React from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollSmoother from "gsap/ScrollSmoother"
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {Link} from "react-router-dom";
import axios from 'axios'



//P1

//P2


//P3

import Footer from "./Footer/Footer";
import P1 from "./pages/P1";
import ImageTitle from "./blocks/ImageTitle";
import ProjectBlock from "./blocks/ProjectBlock";
import CharityGallery from "./blocks/CharityGallery";
import PopUp from "./pages/PopUp";

import Config from "../../Config";
import AnimateSvg from "./blocks/AnimateSvg";
import PopUpAlert from "./pages/PopUpAlert";


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.content = React.createRef();
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
            page_slug:null
        };
    }

    componentDidMount() {

        const ease = "sine.inOut"

        // // create the smooth scroller FIRST!
        // this.smoother = ScrollSmoother.create({
        //     // wrapper: this.wrapper.current,
        //     // content: this.content.current,
        //     smooth:1,   // seconds it takes to "catch up" to native scroll position
        //     effects: false,
        //     smoothTouch: 0.1
        //     // preventDefault:true
        // });
        // this.smoother =ScrollSmoother.create({
        //     smooth: 3,               // how long (in seconds) it takes to "catch up" to the native scroll position
        //     effects: false,           // looks for data-speed and data-lag attributes on elements
        //     smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        // });

        //
        ScrollTrigger.create({
            trigger: ".Home",
            start: "top top",
            end: "bottom 50%+=100px",
            onToggle: self => console.log("toggled, isActive:", self.isActive),
            onUpdate: self => {
                // console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
            }
        });

        const that = this;

        let homeAPI=Config.API+'/dd/home';

        // homeAPI=Config.API+'/dd/home_test';//如果是测试环境用这个




        axios.get(homeAPI).then(function (response) {
            // handle success
            that.setState({
                isLoaded: true,
                data: response.data
            })

            console.log(response.data);
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });


        // this.smoother.scrollTo('.Home',true)

    }

    scrollTo(id) {
        // console.log(id)
        // this.smoother.scrollTo(id, true)
        gsap.to(window, {duration: 0.5, scrollTo: id});

    }

    popUp(url) {

        document.body.style.overflow='hidden';
        document.body.style.height='100vh'


        this.setState({page_slug:url})
        // console.log(url)
    }
    close(){
        document.body.style.overflow='';//出现滚动条
        document.body.style.height='auto'
        // this.props.close()
        this.setState({page_slug:null})
    }

    componentWillUnmount() {
        // this.smoother.kill()
        console.log('kill')

    }

    render() {
        const {error, isLoaded, data,page_slug} = this.state;
        let CDN=Config.CDN;



        if (!isLoaded) {

            return <div className='loading'>

                <AnimateSvg src={CDN+'./assets/home/loading.svg'} width='100px' height='100px' backup={CDN+'./assets/home/loading.png'}></AnimateSvg>

            </div>;
        } else {


            return (

                <div className="Home">
                    <div className={page_slug?'blur-home':''}>


                    {/*<div id="smooth-wrapper" ref={this.wrapper}>*/}
                    {/*    <div id="smooth-content" ref={this.content}>*/}
                    <P1 scrollTo={this.scrollTo.bind(this)} links={data['links']}/>


                    {Object.keys(data['block_data']).map((index) => (

                        <div id={index} key={index} style={{marginTop:'80px'}}>
                            <ImageTitle src={CDN+"./assets/home/assets/" + index + ".png"}></ImageTitle>
                            {data.block_data[index].map(item => (

                                <ProjectBlock popUp={this.popUp.bind(this)} key={item.id} type={item.list_type}
                                              png={item.list_png_url} svg={item.list_image_url}
                                              title={item.list_title_url}
                                              btn={item.list_button_url}
                                              text={item.list_text} link={item.slug}/>
                            ))}
                        </div>
                    ))}
                    <CharityGallery popUp={this.popUp.bind(this)}></CharityGallery>

                    <Footer></Footer>

                    </div>

                    <PopUp close={this.close.bind(this)} page_slug={page_slug}></PopUp>

<PopUpAlert alert={data.alert}></PopUpAlert>

                </div>

            );
        }
    }
}


export default Home;
