import './Article.css';
import React from "react";
import { Outlet, Link, useRoutes, useParams } from "react-router-dom";
import ProjectBlock from "../home/blocks/ProjectBlock";
import PopUp from "../home/pages/PopUp";
import Config from "../../Config";
// import {gsap} from "gsap/gsap-core";
// import ScrollSmoother from "gsap/ScrollSmoother"
// import ScrollTrigger from "gsap/ScrollTrigger"

function Article() {
    let { slug } = useParams();
    let CDN=Config.CDN;
    return (
        <div style={{ backgroundImage: `url('`+CDN+`./assets/article/mhbj.jpg')`,width:"100vw",height:"100vh" }}>
            <PopUp  page_slug={slug} close_icon={false}></PopUp>
        </div>
    );
}
// class Article extends React.Component {
//     componentDidMount() {
//
//         let { id } = this.props;
//         console.log(useParams());
//     }
//
//     componentWillUnmount() {
//
//     }
//
//     render() {
//
//         // tl.to(".Home-video-background", {xPercent: 350, duration: 1})
//         return (
//             <div id="smooth-wrapper">
//                 <div id="smooth-content">
//                     <header className="App-header">
//
//                     </header>
//                 </div>
//             </div>
//
//         );
//     }
// }


export default Article;
