import React from "react";
import './project-block.scss'
import AnimateSvg from "./AnimateSvg";

class ProjectBlock extends React.Component {

    click(e){
        console.log(this.props)
        this.props.popUp(this.props.link);

    }

    render() {


        return (<div className={'project-block '+this.props.type}  style={{'marginTop': '40px'}}>
            {/*<img className='image' src={this.props.img}/>*/}
            {/*<object className='image' style={{textAlign:"right"}} width='100%' height='600px' type="image/svg+xml" data={this.props.img}></object>*/}
            <AnimateSvg className='image' width='700px' height='600px' src={this.props.svg} backup={this.props.png}></AnimateSvg>
            <div className='info'>
                <div>
                    <img src={this.props.title}/>
                </div>
                <div className="text" dangerouslySetInnerHTML={{ __html: (this.props.text) }} >

                </div>
                <div className='btn' onClick={this.click.bind(this)}  >
                    <img src={this.props.btn}/>
                </div>
            </div>
        </div>)
    }
}

export default ProjectBlock;